const hotelAdType = {
  "TYPES" : [
    {
      "Value" : 'ADVERTISEMENT',
      "Title" : 'REKLAM KATKI',
    },
    {
      "Value" : 'CATALOG',
      "Title" : 'KATALOG KATKI',
    },
    {
      "Value" : 'NEWSPAPER',
      "Title" : 'GAZETE KATKI',
    },
    {
      "Value" : 'DIGITAL',
      "Title" : 'DİJİTAL REKLAM KATKI',
    }],
};
export default hotelAdType;
