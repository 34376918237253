
import {computed, defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";
import {dateConvert} from "@/core/filters/datatime";

import ItemUpdate from "@/components/hotel/AdUpdate.vue";
import ItemAddNew from "@/components/hotel/AdAddNew.vue";
import {commonf} from "@/core/filters/common";

export default defineComponent({
  name: "overview",
  components: {
    ItemAddNew,
    ItemUpdate,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Otel Reklam Katkı", ["Otel"]);
    });

    const route = useRoute();
    const id = route.params.id;
    const payload = {
      ID: id,
    }
    //console.log(payload);
    store.dispatch(Actions.HOTEL_DETAILS, payload);
    const myDetails = computed(() => {
      return store.getters.hotelDetails;
    });

    const payload3 = {
      HotelID: id,
      is_active: "active",
    }
    store.dispatch(Actions.HOTEL_AD_LIST, payload3);
    const myList = computed(() => {
      return store.getters.hotelAdList;
    });

    const updateActive = (ID, Active) => {
      const payload = {
        ID: ID,
        HotelID: id,
        is_active: (Active == 'active') ? "passive" : "active",
      }
      store.dispatch(Actions.HOTEL_AD_EDIT, payload)
    }

    const deleteItem = (ID) => {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload = {
            ID: ID,
            HotelID: id,
          }
          store.dispatch(Actions.HOTEL_AD_DELETE, payload)
              .then(() => {
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getHotelAdErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });

    }

    let itemID = ref<number>(0);
    function setItems(ID) {
      itemID.value=ID;
    }

    return {
      id,
      myDetails,
      myList,
      dateConvert,
      itemID,
      setItems,
      updateActive,
      deleteItem,
      commonf,
    };
  },
});
