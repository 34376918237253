
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import roomProfitType from "@/core/data/roomprofittype";
import {dateConvert} from "@/core/filters/datatime";
import hotelAdType from "@/core/data/hoteladtype";
import curList from "@/core/data/currency";

interface newItemData {
  itemTitle: string;
  itemStart: string;
  itemFinish: string;
  itemDepartments: string;
  itemMarkets: string;
  itemTargetPrice: string;
  itemInvoiceDate: string;
  itemAdType: string;
  itemType: string;
  itemAmount: string;
  itemCur: string;
}

export default defineComponent({
  name: "new-over-commission-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      startpicker: null,
      endpicker: null,
    }
  },
  mounted() {
    this.datapicker();
    return true;
  },
  methods: {
    datapicker() {
      let config = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
      };
      this.startpicker = flatpickr("#kt_datepicker_1", config);
      this.endpicker = flatpickr("#kt_datepicker_2", config);
      this.endpicker = flatpickr("#kt_datepicker_3", config);
    },
  },
  props: {
    hotelID: String,
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);


    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });



    const newItemData = ref<newItemData>({
      itemTitle: "",
      itemStart: "",
      itemFinish: "",
      itemDepartments: "",
      itemMarkets: "",
      itemTargetPrice: "",
      itemInvoiceDate: "",
      itemAdType: "",
      itemType: "",
      itemAmount: "",
      itemCur: "",
    });

    const validationSchema = Yup.object().shape({
      itemTitle: Yup.string().required().label("Başlık"),
      itemStart: Yup.string().required().label("Başlangıç Tarihi"),
      itemFinish: Yup.string().required().label("Bitiş Tarihi"),
      itemInvoiceDate: Yup.string().required().label("Fatura Tarihi"),
      itemAdType: Yup.string().required().label("Katkı Türü"),
      itemType: Yup.string().required().label("Komisyon Türü"),
      itemAmount: Yup.string().required().label("Komisyon Miktarı/Oranı"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");


      const payload1 = {
        HotelID: myDetails2.value.ID,
        Title: newItemData.value.itemTitle,
        StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
        FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
        Markets: JSON.stringify(newItemData.value.itemMarkets),
        Departments: JSON.stringify(newItemData.value.itemDepartments),
        TargetPrice: newItemData.value.itemTargetPrice,
        InvoiceDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemInvoiceDate),
        AdType: newItemData.value.itemAdType,
        Type: newItemData.value.itemType,
        Amount: newItemData.value.itemAmount,
        Cur: newItemData.value.itemCur,
      }
      //console.log(payload1);

      store.dispatch(Actions.HOTEL_AD_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getHotelAdErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };


    /***********MARKETS START************/
    /***********MARKETS START************/
    /***********MARKETS START************/
    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });
    const myTagsMarkets = computed(() => {
      if (myMarkets.value.data) {
        let tmpList = [];
        for (let i = 0; i < myMarkets.value.data.length; i++) {
          tmpList[i] = {
            value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
            title: (myMarkets.value.data[i].ShortTitle).trim(),
            cur: myMarkets.value.data[i].Cur,
            type: 'market',
            ID: myMarkets.value.data[i].ID,
          };

        }
        setTagInput(tmpList)
        return tmpList;

      } else {
        return [];
      }
    });
    let tagify;
    const setTagInput = (wList) => {
      var input = document.querySelector('input[name=myTagsInput]');
// init Tagify script on the above inputs
      tagify = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });


    }
    function onMarketChange(e) {
      //console.log(e.target.value)
      let tmpObj = JSON.parse(e.target.value);
      newItemData.value.itemMarkets = tmpObj;
    }
    /***********MARKETS END************/
    /***********MARKETS END************/
    /***********MARKETS END************/


    /************DEPARTMENTS START************/
    /************DEPARTMENTS START************/
    /************DEPARTMENTS START************/
    /************DEPARTMENTS START************/
    store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
    const myDepartments = computed(() => {
      const tmp= store.getters.DepartmentsList;
      //console.log(tmp)
      return tmp;
    });

    const myTagsDepartments = computed(() => {
      if (myDepartments.value.data!== undefined) {
        let tmpList = [];
        for (let i = 0; i < myDepartments.value.data.length; i++) {
          tmpList[i] = {
            value: (myDepartments.value.data[i].Title).trim(),
            title: (myDepartments.value.data[i].Shorthand).trim(),
            type: 'Departments',
            ID: myDepartments.value.data[i].ID,
          };

        }
        setTagInputDepartments(tmpList)
        return tmpList;
      } else {
        return [];
      }
    });
    let tagify2;
    const setTagInputDepartments = (wList) => {
      var input = document.querySelector('input[name=myTagsInputDepartments]');
// init Tagify script on the above inputs
      tagify2 = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });


    }
    function onDepartmentChange(e) {
      let tmpObj = JSON.parse(e.target.value);
      newItemData.value.itemDepartments = tmpObj;
    }
    /************DEPARTMENTS END************/
    /************DEPARTMENTS END************/
    /************DEPARTMENTS END************/
    /************DEPARTMENTS END************/

    return {
      myDetails2,
      newItemData,
      myTagsMarkets,
      myTagsDepartments,
      validationSchema,
      submit,
      onDepartmentChange,
      onMarketChange,
      submitButtonRef,
      newRoomModalRef,
      roomProfitType,
      dateConvert,
      hotelAdType,
      curList,
    };
  },
});
